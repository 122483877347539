import { createApp } from "./app";
import posthog from "posthog-js";
import { hydrate } from "@tanstack/vue-query";
import type { PageContextClient } from "vike/types";
import {
  init as sentryInit,
  browserTracingIntegration,
  browserProfilingIntegration,
  replayIntegration,
} from "@sentry/vue";
import "~/scss/index.scss";
import "floating-vue/dist/style.css";

let app: Awaited<ReturnType<typeof createApp>>["app"];

export async function onRenderClient(pageContext: PageContextClient) {
  if (!app) {
    const obj = await createApp(pageContext);
    app = obj.app;
    const { environment, sentryDsn, sentryRelease, posthogKey } =
      pageContext.envs;

    if (import.meta.env.PROD) {
      registerSentry(sentryDsn, environment, sentryRelease);
      registerPosthog(posthogKey);
    }

    obj.store.state.value = pageContext.INITIAL_STATE;
    hydrate(obj.queryClient, pageContext.vueQueryState);
    app.mount("#app");
  } else {
    app.changePage(pageContext);
  }
}

function registerPosthog(key: string) {
  posthog.init(key, {
    api_host: "/ph/ingest",
    ui_host: "https://eu.posthog.com",
    persistence: "memory",
  });
  app.provide("posthog", posthog);
}

function registerSentry(dsn: string, environment: string, release: string) {
  sentryInit({
    app,
    dsn,
    integrations: (defaults) =>
      defaults.concat(
        browserTracingIntegration(),
        browserProfilingIntegration(),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        })
      ),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    profilesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment,
    release,
    tracePropagationTargets: [
      "localhost",
      /^\//,
      /^https:\/\/api\.(.*\.)?lexmea\.de/,
    ],
  });
}
