import ky from "ky";
import type { PageContext } from "vike/types";
import { hasInjectionContext } from "vue";

/**
 * Our server should act as a proxy for the client, so we need to forward cookies and set proxy headers
 */
function serverHeaders(pageContext: PageContext) {
  const { cookies, host, protocol } = pageContext;

  const cookieString = Object.entries(cookies ?? {})
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("; ");

  return {
    Cookie: cookieString,
    Origin: `${protocol}://${host}`,
    "X-Forwarded-Host": host,
    "X-Forwarded-Proto": protocol,
  };
}

export function buildApiClient(pageContext?: PageContext) {
  if (!pageContext && !hasInjectionContext()) {
    throw new Error(
      "No pageContext provided and no injection context available"
    );
  }
  const ctx = pageContext ?? usePageContext();
  const prefixUrl = import.meta.env.SSR
    ? process.env.VITE_API_SSR_HOST
    : ctx.envs.apiBaseUrl;

  return ky.create({
    prefixUrl,
    credentials: "include",
    retry: {
      limit: 0,
    },
    headers: {
      Accept: "application/json",
    },
    hooks: {
      beforeRequest: [
        (request) => {
          let token: string | undefined;
          if (globalThis.window === undefined) {
            token = ctx.cookies?.["XSRF-TOKEN"];
          } else {
            token = document.cookie
              .split("; ")
              .find((row) => row.startsWith("XSRF-TOKEN="))
              ?.split("=")[1];
          }

          let headers = {
            "X-Requested-With": "XMLHttpRequest",
            "X-XSRF-TOKEN": token ? decodeURIComponent(token) : undefined,
          };

          if (globalThis.window === undefined) {
            headers = {
              ...headers,
              ...serverHeaders(ctx),
            };
          }

          Object.entries(headers)
            .filter(([, value]) => Boolean(value))
            .forEach(([key, value]) => request.headers.set(key, value!));

          return request;
        },
      ],
    },
  });
}
