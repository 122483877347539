export function useParagraphSearch(
  query: MaybeRef<string>,
  legalTextId: MaybeRef<number | undefined>
) {
  const { refetch, ...rest } = useQuery({
    ...queries().paragraphs.search(query, legalTextId),
    // disable automatic fetching
    enabled: false,
  });

  const data = computed(() => (toValue(query) ? rest.data.value : undefined));

  debouncedWatch(
    () => toValue(query),
    (q) => !!q && refetch(),
    { debounce: 300 }
  );

  return { ...rest, data };
}
