export function useCurrentParagraph() {
  const routeParams = useRouteParams();

  const legalTextSlug = computed(() => routeParams.legalTextSlug);
  const paragraphSlug = computed(() => routeParams.legalTextParagraphSlug);

  const query = useFetchParagraphBySlug(legalTextSlug, paragraphSlug);

  return query;
}
