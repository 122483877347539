export function useNoteSearch(query: Ref<string>) {
  const { refetch, ...rest } = useQuery({
    ...queries().entries.search(query)._ctx.notes,
    enabled: false,
  });

  const data = computed(() => (query.value ? rest.data.value : undefined));

  debouncedWatch(query, (q) => !!q && refetch(), { debounce: 300 });

  return { ...rest, data };
}
