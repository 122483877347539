import { BookmarkClient } from "~/api/bookmark";
import { EntriesClient } from "~/api/entries";
import { FieldOfLawClient } from "~/api/fieldOfLaw";
import { LegalTextClient } from "~/api/legalText";
import { LegalTextParagraphClient } from "~/api/legalTextParagraph";
import { TableOfContentsClient } from "~/api/tableOfContents";
import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import type { IDetailUserEntry, IParagraphEntry } from "~/models/IUserEntry";
import { ArticlesClient } from "~/api/articles";
import { BookshelfClient } from "~/api/bookShelf";
import { TabsClient } from "~/api/tabs";
import { hasInjectionContext, type InjectionKey } from "vue";
import { PageContext } from "vike/types";
import { ApiClient, LexmeaApiClient } from "~/api/apiClient";
import { ColumnSort } from "~/models/IBookshelf";

export const queriesKey: InjectionKey<ReturnType<typeof createQueries>> =
  Symbol("tanstack-queries");

export const queries = () => {
  if (!hasInjectionContext()) {
    throw new Error("Queries can only be used in vue context");
  }
  const store = injectLocal(queriesKey);
  if (store) {
    return store;
  }
  throw new Error("No queries provided");
};

export const provideQueries = (
  q: ReturnType<typeof createQueries> = createQueries()
) => {
  provideLocal(queriesKey, q);
};

export const createQueries = (pageContext?: PageContext) => {
  const makeQueryClient = <T extends LexmeaApiClient>(
    client: new (client: ApiClient) => T
  ) => useApiClient(client, pageContext);
  const legalTextClient = makeQueryClient(LegalTextClient);
  const bookshelfClient = makeQueryClient(BookshelfClient);
  const tabsClient = makeQueryClient(TabsClient);
  const entriesClient = makeQueryClient(EntriesClient);
  const tocClient = makeQueryClient(TableOfContentsClient);
  const articlesClient = makeQueryClient(ArticlesClient);
  const bookmarkClient = makeQueryClient(BookmarkClient);
  const fieldOfLawClient = makeQueryClient(FieldOfLawClient);
  const legalTextParagraphClient = makeQueryClient(LegalTextParagraphClient);

  return createQueryKeyStore({
    articles: {
      all: {
        queryKey: null,
        queryFn: articlesClient.fetchArticles,
      },
      bySlug: (slug: MaybeRef<string>) => ({
        queryKey: [{ slug }],
        queryFn: () => articlesClient.fetchArticleBySlug(unref(slug)),
      }),
    },
    bookmarks: {
      tree: {
        queryKey: null,
        queryFn: bookmarkClient.fetchBookmarkTree,
      },
      all: {
        queryKey: null,
        queryFn: bookmarkClient.fetchBookmarks,
      },
    },
    bookshelf: {
      userContentTree: (
        onOldParagraphs: MaybeRef<boolean>,
        onlyOwnSchemas: MaybeRef<boolean> = false
      ) => ({
        queryKey: [{ onOldParagraphs, onlyOwnSchemas }],
        queryFn: () =>
          bookshelfClient.fetchUserContentTree(
            toValue(onOldParagraphs),
            toValue(onlyOwnSchemas)
          ),
      }),
      contentCount: {
        queryKey: null,
        queryFn: bookshelfClient.fetchUserContentCount,
      },
      chronikEntries: (
        sorting: MaybeRef<ColumnSort>,
        type: MaybeRef<string>,
        deleted: MaybeRef<boolean>,
        page: MaybeRef<number>
      ) => ({
        queryKey: [{ sorting, type, deleted, page }],
        queryFn: () =>
          bookshelfClient.fetchEntries(
            toValue(sorting),
            toValue(type),
            toValue(deleted),
            toValue(page)
          ),
      }),
    },
    entries: {
      byType: <T extends IDetailUserEntry["type"]>(type: MaybeRef<T>) => ({
        queryKey: [{ type }],
        contextQueries: {
          byId: (id: MaybeRef<number>) => ({
            queryKey: [{ id }],
            queryFn: () =>
              entriesClient.fetchEntryById<T>(unref(id), unref(type)),
          }),
          bySlug: (slug: MaybeRef<string>) => ({
            queryKey: [{ slug }],
            queryFn: () => {
              if (unref(type) !== "schema") {
                throw new Error("Only schemas have slugs");
              }
              return entriesClient.fetchSchemaBySlug(unref(slug));
            },
          }),
        },
      }),
      search: (query: MaybeRef<string>) => ({
        queryKey: [{ query }],
        contextQueries: {
          schemas: {
            queryKey: null,
            queryFn: () => entriesClient.searchSchemas(unref(query)),
          },
          notes: {
            queryKey: null,
            queryFn: () => entriesClient.searchNotes(unref(query)),
          },
        },
      }),
    },
    fieldOfLaws: {
      all: {
        queryKey: null,
        queryFn: fieldOfLawClient.fetchAllFieldsOfLaw,
      },
      byId: (id: MaybeRef<number | undefined>) => ({
        queryKey: [{ id }],
        queryFn: () => fieldOfLawClient.fetchFieldOfLaw(unref(id)!),
        contextQueries: {
          schemas: {
            queryKey: null,
            queryFn: () => entriesClient.fetchSchemasByFieldOfLaw(unref(id)!),
          },
        },
      }),
    },
    legalTexts: {
      all: {
        queryKey: null,
        queryFn: legalTextClient.fetchAllLegalTexts,
      },
      byId: (id: MaybeRef<number | undefined>) => ({
        queryKey: [{ id }],
        queryFn: () => legalTextClient.fetchLegalTextById(unref(id)!),
      }),
      bySlug: (slug: MaybeRef<string | undefined>) => ({
        queryKey: [{ slug }],
        queryFn: () => legalTextClient.fetchLegalTextBySlug(unref(slug)!),
        contextQueries: {
          paragraphBySlug: (paragraphSlug: MaybeRef<string | undefined>) => ({
            queryKey: [{ paragraphSlug }],
            queryFn: () =>
              legalTextParagraphClient.fetchParagraphBySlug(
                unref(slug)!,
                unref(paragraphSlug)!
              ),
          }),
          tableOfContents: {
            queryKey: null,
            queryFn: () => tocClient.fetchTableOfContents(unref(slug)!),
          },
        },
      }),
    },
    paragraphs: {
      byId: (id: MaybeRef<number | undefined>) => ({
        queryKey: [{ id }],
        queryFn: () => legalTextParagraphClient.fetchParagraphById(unref(id)!),
        contextQueries: {
          entriesByParagraph: <T extends IParagraphEntry["type"]>(
            type: MaybeRef<T>
          ) => ({
            queryKey: [{ type }],
            queryFn: () =>
              entriesClient.fetchEntryByParagraph<T>(unref(id)!, unref(type)),
          }),
          incomingLinkChains: {
            queryKey: null,
            queryFn: () => entriesClient.fetchIncomingLinkChains(unref(id)!),
          },
        },
      }),
      search: (
        query: MaybeRef<string>,
        legalTextId: MaybeRef<number | undefined>
      ) => ({
        queryKey: [{ query }, { legalTextId }],
        queryFn: () =>
          legalTextParagraphClient.searchParagraphs(
            unref(query),
            unref(legalTextId)
          ),
      }),
    },
    tabs: {
      all: {
        queryKey: null,
        queryFn: tabsClient.getUserTabs,
      },
    },
  });
};
