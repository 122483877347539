import * as Sentry from "@sentry/vue";

export function useAuth() {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  // TODO: implement this using ky hooks
  // const addAxiosAuthInterceptor = () =>
  //   createAuthRefreshInterceptor(
  //     axios,
  //     async (failedRequest) => {
  //       try {
  //         await userStore.refreshUser();
  //       } catch (e) {
  //         // we need to reject the original request here for vue-query to work
  //         return Promise.reject(failedRequest);
  //       }
  //     },
  //     {
  //       statusCodes: [401, 419],
  //     }
  //   );

  onServerPrefetch(userStore.refreshUser);

  onMounted(() => {
    window._paq?.push(["setUserId", user.value?.id.toString()]);

    if (user.value) {
      // addAxiosAuthInterceptor();
      Sentry.setUser({ ...user.value, id: user.value.id.toString() });
    }
  });
}
