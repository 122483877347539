export default function useLibrarySubPagePersisted() {
  const lastSubPagePersisted = useLocalStorage<string | null>(
    "last-visited-library-subpage",
    null,
    {
      initOnMounted: true,
    }
  );

  function setLastSubPagePersisted(subpage: string) {
    if (
      subpage === lastSubPagePersisted.value ||
      !subpage.startsWith("/bibliothek/" || subpage === "/bibliothek/")
    ) {
      return;
    }
    lastSubPagePersisted.value = subpage;
  }

  return {
    lastSubPagePersisted: readonly(lastSubPagePersisted),
    setLastSubPagePersisted,
  };
}
