export function useFetchParagraphBySlug(
  legalTextSlug: MaybeRef<string | undefined>,
  slug: MaybeRef<string | undefined>
) {
  const enabled = logicAnd(legalTextSlug, slug);

  const resolvedQueries = queries();

  const query = useQuery({
    ...resolvedQueries.legalTexts
      .bySlug(legalTextSlug)
      ._ctx.paragraphBySlug(slug),
    enabled,
  });

  const queryClient = useQueryClient();

  whenever(query.data, (paragraph) => {
    queryClient.setQueryData(
      resolvedQueries.paragraphs.byId(paragraph.id).queryKey,
      paragraph
    );
  });

  return { ...query, enabled };
}
