<template>
  <VueFinalModal
    :modelValue="modelValue"
    class="!z-40 flex items-center justify-center max-lg:px-2"
    :contentClass="[
      'relative z-40 mx-auto flex max-h-[var(--total-height)] w-full max-w-lg flex-col max-md:w-screen md:max-h-[90vh]',
      contentClass,
    ]"
    :modalId="modalId"
    overlayTransition="vfm-fade"
    contentTransition="vfm-fade"
    teleportTo="#teleported"
    :clickToClose="!closingDisabled"
    :escToClose="!closingDisabled"
    :zIndexFn="(context) => 40 + 2 * context.index"
    @update:modelValue="$emit('update:modelValue', $event)"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
    @beforeOpen="$emit('beforeOpen', $event)"
    @beforeClose="$emit('beforeClose', $event)"
    @clickOutside="$emit('clickOutside')"
  >
    <template #default="{ close }">
      <div
        class="text-18-px static top-0 grid grid-cols-5 grid-rows-1 items-center rounded-t bg-wild-sand text-center"
      >
        <component
          :is="titleTag"
          class="col-start-2 col-end-5 font-bold first-letter:capitalize md:col-start-2"
        >
          <slot name="title" />
        </component>
        <button
          v-if="!closingDisabled"
          v-ripple="{ color: 'blue' }"
          class="m-2 ml-auto rounded-full p-2 transition hover:bg-gray-200"
          @click="close"
        >
          <i-heroicons-x-mark-20-solid class="h-5 w-5" />
        </button>
      </div>
      <div
        class="grow overflow-y-auto bg-white px-6"
        :class="{
          'py-4': !noPaddingY,
        }"
      >
        <slot :close="close" />
      </div>
      <slot name="footer" :close="close">
        <div class="rounded-b bg-white p-2"></div>
      </slot>
    </template>
  </VueFinalModal>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  type ModalId,
  VueFinalModal,
  type VueFinalModalEmits,
} from "vue-final-modal";

defineEmits<VueFinalModalEmits>();

type Props = {
  titleTag?: string;
  modelValue?: boolean;
  modalId?: ModalId;
  closingDisabled?: boolean;
  noPaddingY?: boolean;
  fullscreenOnMobile?: boolean;
  contentClass?: string;
};

withDefaults(defineProps<Props>(), {
  titleTag: "h1",
  modalId: undefined,
  closingDisabled: false,
  noPaddingY: false,
  fullscreenOnMobile: false,
  contentClass: "",
});

const { vRipple } = useVRipple();
</script>
